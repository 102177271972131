@import "vars";

@mixin infoIcon {
  border-radius: 50%;
  color: white;
  font-weight: 500;
  z-index: 2;
  img {
    width: 100%;
  }
  //background: var(--linkColor);
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#dashboardLayout {
  display: flex;
  width: 100%;
  background: #f6f6f6;
  // display: grid;
  // grid-template-columns: repeat(12, 1fr);
  // grid-auto-flow: row;
  //column-gap: 39px;
  overflow: hidden;
  min-height: 100vh;
  flex-grow: 1;
  @media screen and (max-width: $mobileScreen) {
    min-height: unset;
    display: block;
    background: white;
  }
}

@keyframes overlayIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes overlayOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.render {
  visibility: hidden;
  opacity: 0;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: $overlay;
}

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;
  padding: 36px 70px 50px 70px;
  box-shadow: 0 82px 103px -30px rgba(46, 56, 29, 0.4);

  @media screen and (max-width: $mobileScreen) {
    width: 100%;
    left: 0;
    transform: translate(0, 0);
    top: unset;
    bottom: 0;
    padding: 1rem;
  }

  h2 {
    font-weight: bold;
    font-size: 25px;
    color: var(--textColor);
    display: block;
    margin: 0 auto 13px;
    text-align: center;
  }

  p {
    font-size: 14px;
    color: var(--textColor);
  }
}

.contactUsModal {
  width: 552px;

  p {
    height: 51px;
    text-align: center;
  }

  .selectLabel {
    position: relative;

    .selectChevron {
      position: absolute;
      top: 56%;
      transform: translateY(-50%);
    }

    .selectWrapper {
      direction: ltr;
      position: relative;
      cursor: pointer !important;

      input {
        cursor: pointer !important;
      }
    }

    span {
      position: absolute;
      top: -2px;
      left: 25px;
      transform: translateY(-50%);
      background: white;
      padding: 1px 12px 1px 8px;
      font-size: 15px;
      transition: all 0.1s ease-in;
      z-index: 1;
    }

    input[type="text"],
    select,
    textarea {
      transition: all 0.1s ease-in;
      font-size: 15px;
      border: 1px solid var(--textColor);
      border-radius: 10px;
      height: 45px;
      padding-left: 25px;
      width: 100%;
      color: var(--textColor);
      background: white;

      &:focus {
        outline: none;
        border-color: var(--linkColor);
      }
    }

    option {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      line-height: 2rem !important;
    }

    select {
    }

    textarea {
      padding: 14px;
      font-family: sans-serif;
      resize: none;
      height: 158px;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;

    .buttonAction {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 43px;
      padding: 3px 53px;
      width: 50%;
      border-radius: 8px;
      background-color: var(--linkColor);
      color: white;
      margin-left: 10px;
      cursor: pointer;
      transition: all 0.1s ease-in;

      &:hover {
        background: rgba(105, 188, 123, 0.95);
      }
    }

    .buttonCancel {
      cursor: pointer;
      width: 50%;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 43px;
      padding: 3px 53px;
      border-radius: 8px;
      border: solid 1px rgba(105, 188, 123, 0.2);
      color: var(--linkColor);
      transition: all 0.1s ease-in;

      &:hover {
        border: solid 1px rgba(105, 188, 123, 0.4);
      }
    }
  }

  @media screen and (max-width: $mobileScreen) {
    width: 100vw;
  }
}

.inputFocus {
  span {
    color: var(--linkColor) !important;
  }
}

.selectLabel.inputError {
  span {
    color: var(--red) !important;
  }
}

input.inputError,
textarea.inputError {
  border-color: var(--red) !important;
}

#sideBar.sideBarIn {
  box-shadow: 0 0 0 350px rgba(0, 0, 0, 0.35);
  transform: translateX(0);
}

#sideBar.sideBarOutRtl {
  transform: translateX(100%);
}

#sideBar.sideBarOut {
  transform: translateX(-100%);
}

#sideBarWrapper {
  grid-column: span 1;

  @media screen and (max-width: 1440px) {
    grid-column: span 2;
  }
}

#sideBar {
  //min-height: 100%;
  grid-column: span 2;
  background: white;
  padding: 26px 0 10px;
  height: 100%;

  @media screen and (max-width: 1440px) {
    //padding: 26px 15px 10px;
  }

  @media screen and (max-width: $mobileScreen) {
    // transition: all 500ms cubic-bezier(0, 0.27, 0.465, 1.01);
    position: fixed;
    z-index: 111;
    width: 70%;
    height: 100%;
    box-shadow: 0 0 0 0 rgba(28, 34, 18, 0.37);
    // transform: translateX(-100.5%);
    top: 0;
    margin-top: 0;
    padding-top: 0;
  }

  #giftButton {
    &:hover {
      background: var(--lightGreen);
      color: #fff;
      border: 1px solid var(--lightGreen);
      .icon {
        background: #fff;
      }
    }

    margin-top: 1rem;
    font-size: 16px;
    display: flex;
    cursor: pointer;
    border: 1px solid #505050;
    color: #505050;
    align-items: center;
    gap: 18px;
    justify-content: center;
    padding: 5px 22px;
    border-radius: 8px;

    @media screen and (max-width: $mobileScreen) {
      margin: 20px 10px 0 10px !important;
    }

    .icon {
      width: 20px !important;
      margin: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .giftIcon {
      mask: url("../public/images/gift.svg") no-repeat center / contain;
      background: var(--lightGreen);
    }
  }

  #giftButtonTrackerRef {
    border: 1px solid #505050;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 5px 22px;
    margin-top: 1rem;
    color: #505050;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    gap: 18px;

    @media screen and (max-width: $mobileScreen) {
      margin: 20px 10px 0 10px !important;
    }

    .icon {
      width: 20px !important;
      margin: 0 !important;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    &:hover {
      background: var(--blue);
      color: #fff;
      border: 1px solid var(--blue);
      .icon {
        background: #fff;
      }
    }

    .giftIcon {
      mask: url("../public/images/gift.svg") no-repeat center / contain;
      background: var(--blue);
    }
  }

  #contactUsWrapper {
    background: #2c3141;
    border-radius: 18px;
    padding: 33px 20px;
    text-align: center;
    margin: auto 15px 0;
    width: 260px;

    @media screen and (max-width: 1440px) {
      margin: auto 10px 0;
      width: unset;
    }

    @media screen and (max-width: $mobileScreen) {
      display: none;
    }

    #help {
      font-size: 23px;
      color: white;
      font-weight: bold;
      @media screen and (max-width: 1440px) {
        font-size: 19px;
      }
    }

    p {
      font-size: 17px;
      color: rgba(255, 255, 255, 0.5);
      margin-top: 8px;
      margin-bottom: 30px;
    }

    .links {
      font-size: 0.73vw;
      color: white;
      font-weight: bold;
      margin-bottom: 6px;
      display: block;

      @media screen and (max-width: 1440px) {
        font-size: 14px;
      }
    }
  }

  #sideBarContent {
    height: calc(100vh - 50px);
    position: sticky;
    top: 39px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $mobileScreen) {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      top: 0;
      margin-top: 0;
    }

    #logo {
      color: #6e957a;
      font-size: 28px;
      font-weight: bold;
      display: block;
      //display: flex;
      //align-items: center;
      margin-bottom: 33px;

      @media screen and (max-width: 1440px) {
        margin-top: -15px;
      }

      @media screen and (max-width: $mobileScreen) {
        display: block;
        margin-top: 1.5rem;
      }

      #logoContainer {
        width: 58px;
        height: 58px;
        margin: 0 auto 5px;
        @media screen and (max-width: $mobileScreen) {
          margin: 0 auto;
        }
      }
    }

    .icon {
      // width: 22px;
      // height: 25px;
      // margin-right: 21.4px;
    }

    span,
    a {
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      font-weight: 300;
      display: block;
      color: inherit;
      text-decoration: none;

      @media screen and (max-width: 1440px) {
        // font-size: 12px;
        // font-weight: 400;
      }
    }

    .contactUsPrompt {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: auto;
      margin-bottom: 10px;
      width: 260px;

      @media screen and (max-width: 1440px) {
        width: unset;
      }

      a {
        font-size: 15px;
        font-weight: 500;
        color: var(--blue);
        text-decoration: underline;

        &:first-of-type {
          margin-bottom: 5px;
          direction: ltr;
        }
      }

      .setupFaceId {
        padding: 13px;
        font-size: 15px;
        display: flex;
        gap: 10px;
        color: white;
        border-radius: 28px;
        width: 225px;
        margin-bottom: 20px;
        justify-content: center;
      }
    }

    .openContact {
      border-radius: 38px;
      color: white;
      text-align: center;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 13px 16px;
      gap: 10px;
      cursor: pointer;
      transition: all 0.1s ease-in;
      font-size: 12px;
      width: 200px;
      margin: 36px auto 25px;
      @media screen and (max-width: 1440px) {
        width: unset;
        margin: 36px 15px 25px;
      }

      @media screen and (max-width: 1360px) {
        justify-content: center;
      }

      @media screen and (max-width: 1225px) {
        font-size: 13px;
        padding: 13px 5px;
      }

      @media screen and (max-width: $mobileScreen) {
        gap: 0;
        padding: 13px 1.5rem;
        width: fit-content;
        margin: auto auto 25px;
        font-size: 15px;
      }

      svg {
        @media screen and (max-width: 1360px) {
          display: none;
        }
        @media screen and (max-width: $mobileScreen) {
          display: unset;
          margin-bottom: -3px;
          margin-left: 0.5rem;
          margin-top: -5px;
        }
      }

      &:hover {
        background: rgba(105, 188, 123, 0.95);
      }
    }
  }
}

.mobileLogoText {
  color: var(--linkColor) !important;
  font-size: 15px;
  font-weight: 400;
}

.activePath {
  background: var(--linkColor);
  color: white !important;

  .icon {
    background: white !important;
  }

  * {
    color: white !important;
  }
}

.trackerRefActivePath {
  background: var(--blue);
  color: white !important;

  .icon {
    background: white !important;
  }

  * {
    color: white !important;
  }

  &:hover {
    background: var(--blue);
    color: #fff;

    .icon {
      background: #fff;
    }
  }
}

.innerContentWrapper {
  min-height: 100%;
  width: 100%;
  overflow-y: auto;
}

#main {
  // max-width: 83%;
  flex-grow: 1;
  @media screen and (max-width: 1440px) {
    grid-column: span 7;
  }

  @media screen and (max-width: $mobileScreen) {
    margin-left: 0;
  }

  #content {
    overflow: hidden;
    height: calc(100vh - 104px);
    /* display: grid; */
    /* grid-template-columns: 9fr 3fr; */
    /* grid-auto-flow: column; */
    /* height: 100%; */
    display: flex;

    //overflow: hidden;
    //height: calc(100vh - 104px);
    //display: grid;
    //grid-template-columns: 9fr 3fr;
    //grid-auto-flow: column;

    @media screen and (max-width: 1440px) {
      height: calc(100vh - 70px);
    }

    @media screen and (max-width: $mobileScreen) {
      display: block;
      height: 100% !important;
      min-height: 100vh !important;
    }
  }

  #content.fullWidth {
    display: block !important;
  }
}

#main.wideMain {
  grid-column: span 11;

  #mainInnerContent {
    @media screen and (min-width: $mobileScreen) {
      margin: 0 0 0 26px;
    }
  }

  .header {
    @media screen and (min-width: $mobileScreen) {
      margin: 0 0 0 26px;
    }
  }

  @media screen and (max-width: 1440px) {
    grid-column: span 10;
  }
}

#articleBar {
  grid-column: span 3;
  background: white;
  border-top-left-radius: 8px;
  position: sticky;
  top: 0;
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    grid-column: span 3;
    //width: 22vw;
  }

  @media screen and (max-width: $mobileScreen) {
    padding: 1rem;
    margin: 0;
    height: unset;
    overflow: unset;
  }
  //height: 100vh;
}

#articlesBarTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 34px 25px 10px;

  @media screen and (max-width: $mobileScreen) {
    margin-bottom: 19px;
    display: block;
  }

  @media screen and (max-width: 1440px) {
    padding: 18px 25px 30px;
  }

  a {
    color: var(--textColor);
    font-weight: normal;
    font-size: 15px;
  }

  h2 {
    margin: 0;
    @media screen and (max-width: 1200px) {
      margin-bottom: 0.5rem;
    }
  }
}

.pageTitles {
  width: fit-content;
  font-size: 30px;
  font-weight: 300;
  color: var(--lightGreen);

  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }

  @media screen and (max-width: $mobileScreen) {
    display: none;
  }
}

.mobileKpiScrollBar {
  position: absolute;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  margin: 5px auto;
  height: 2px;
  border-radius: 3px;
  width: 210px;
  background: #ccc;

  .mobileKpiScrollBarThumb {
    position: absolute;
    border-radius: 4px;
    top: -15px;
    transform: translateY(-100%);
    left: 3px;
    width: 23px;
    height: 6px;
    //background: white;
  }
}

.pageTitleWrapper {
  display: flex;
  margin-top: -34px;
  padding-top: 49px;
  line-height: 30px;
  align-items: center;
  margin-bottom: 23px;
  margin-left: 2%;
  gap: 27px;

  @media screen and (max-width: 1440px) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
  }

  span {
    height: 26px;
  }

  svg {
    cursor: pointer;
  }
}

.header {
  padding: 20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  height: 104px;
  margin: 0 26px;
  background: white;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9;
  // transition: all 0.2s ease-in;
  border-bottom: 0 solid rgba(145, 178, 87, 0.3);
  @media screen and (max-width: 1440px) {
    height: 70px;
  }
  @media screen and (max-width: $mobileScreen) {
    position: fixed;
    left: 0;
    top: 0;
    background: white;
    border-radius: 0;
    margin-left: 0;
    height: 59px;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 26px 18px;
    z-index: 99;
  }
}

#mobileAccountName {
  font-size: 24px;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

.mobileHeader {
  display: none;
  @media screen and (max-width: $mobileScreen) {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-right: auto;
  }
}

.headerScrolled {
  //box-shadow: 0 25px 20px -20px rgba(0, 0, 0, 0.25);
  //border-bottom: 1px solid rgba(145, 178, 87, 0.3);
}

#kpiAsOfMobile {
  margin-top: 25px;
  text-align: center;
  font-size: 18px;
}

.kpiGradient {
  @media screen and (min-width: $mobileScreen) {
    display: none;
  }
  position: absolute;
  display: none;
  right: 0;
  top: 185px;
  width: 50px;
  height: 150px;
  z-index: 1;
  background: linear-gradient(90deg, rgba(249, 249, 249, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
}

.mobileKpisHeader {
  position: relative;

  @media screen and (max-width: $mobileScreen) {
    background: linear-gradient(120.55deg, #ffffff 47.37%, #d5d5d5 117.29%) no-repeat;
    background-size: 100% 77%;
    overflow: hidden;
    margin-bottom: -15px;
  }
}

.accountsDropdownModalOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  @media screen and (max-width: $mobileScreen) {
    display: block;
  }
  //background: red;
}

#dashboardHeader {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: white;
  margin: 0 26px;
  padding-bottom: 15px;
  padding-top: 1px;
  position: relative;

  @media screen and (max-width: $mobileScreen) {
    background: transparent;
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    margin: 15px 0 0 0;
    border-radius: 0;
    position: relative;
  }

  .accountData {
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    position: relative;
    @media screen and (max-width: 1440px) {
      padding-bottom: 0;
    }

    @media screen and (max-width: $mobileScreen) {
      display: flex;
      align-items: flex-start;
      justify-content: unset;
      width: unset;
      flex: 1 0 100%;
      position: relative;
      margin-top: 10px;
      margin-bottom: 3rem;
    }

    .dataBlock {
      box-sizing: border-box;
      width: 50%;
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      transition: all 0.3s ease-in;
      background: transparent;

      @media screen and (max-width: $mobileScreen) {
        width: 100%;
        align-items: center;
        border-radius: 0;
        padding: 1rem;
      }

      &:hover {
      }

      &:nth-child(1) {
        position: relative;

        &::before {
          content: "";
          width: 3px;
          background: #c4c4c4;
          height: 40px;
          position: absolute;
          right: -1px;
          top: 50%;
          margin-top: 10px;
          transform: translateY(-50%);
        }
      }

      .dataBlockTitle {
        text-align: left;
        color: var(--textColor);
        line-height: 20px;
        margin-bottom: 0;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 15px;
        position: relative;

        span {
          margin-left: -12px;
        }

        @media screen and (max-width: $mobileScreen) {
          gap: 3px;
          font-weight: 500;
          margin-top: 0;
        }

        img {
          @media screen and (max-width: $mobileScreen) {
            width: 18px !important;
            margin-right: 7px;
          }
        }

        span {
          color: #92948d;
          position: relative;
          //font-size: 20px;
          font-size: 1.05vw;
          @media screen and (max-width: $mobileScreen) {
            font-size: 12px;
          }
        }
      }

      .figure {
        color: var(--textColor);
        font-size: 2.5vw;
        font-weight: bold;
        margin-bottom: 0;
        z-index: 1;

        @media screen and (max-width: $mobileScreen) {
          font-size: 35px;
          line-height: 36px;
        }
      }

      .dataDescription {
        opacity: 0.5;
        font-size: 15px;
        color: var(--textColor);
        line-height: 25px;
        margin: 0;
        @media screen and (max-width: $mobileScreen) {
          font-size: 13px;
          font-weight: 300;
        }
      }
    }
  }

  .kpiHighlight {
    .kpiName,
    .kpiValue {
      color: var(--blue);
    }
  }

  #kpiAsOf {
    text-align: center;
    color: #505050;
    font-size: 18px;
    margin-top: 14px;
    margin-bottom: 14px;
    font-weight: 300;
    @media screen and (max-width: $mobileScreen) {
      display: none;
    }
  }

  #kpis {
    //opacity: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    //overflow: hidden;
    // transition: all 0.4s cubic-bezier(0.39, 0.985, 0.62, 0.97);
    height: 110px;
    @media screen and (max-width: $mobileScreen) {
      height: unset;
      opacity: unset;
      overflow: unset;
      //width: 100%;
      justify-content: unset;
      flex: 1 0 100%;
      margin-left: 20%;
    }
  }

  #kpisToggle {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $mobileScreen) {
      display: none;
    }

    img {
      transition: all 0.4s cubic-bezier(0.39, 0.985, 0.62, 0.97);
      cursor: pointer;
      @media screen and (max-width: 1440px) {
        width: 26px;
      }
    }

    img.toggled {
      transform: rotate(180deg);
    }
  }
}

.kpi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  position: relative;
  width: auto;
  flex: 1 1 33%;

  @media screen and (max-width: 1440px) {
  }

  @media screen and (max-width: $mobileScreen) {
    flex: 1 0 auto !important;
    margin: 0 !important;
    padding: 25px 25px 25px 25px !important;
  }

  .kpiValue {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--textColor);
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 1440px) {
      font-size: 20px;
    }

    @media screen and (max-width: $mobileScreen) {
      font-size: 24px !important;
      font-weight: 400;
    }

    .kpiValuePrecision {
      margin-bottom: 3.5px;
      margin-left: 2px;
      font-size: 18px;

      @media screen and (max-width: 1440px) {
        font-size: 14px;
      }

      @media screen and (max-width: $mobileScreen) {
        font-size: 14px !important;
        margin-bottom: 3.5px !important;
      }
    }
  }

  .kpiName {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 5px;
    @media screen and (max-width: 1440px) {
      font-size: 1vw;
    }
    @media screen and (max-width: $mobileScreen) {
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      //display: inline-block;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 20px;
    }
  }
}

.kpiDivider {
  position: absolute;
  width: 3px;
  background: #c4c4c4;
  height: 66px;
  top: 42%;
  border-radius: 2px;
  right: 0;
  margin-top: 10px;
  transform: translateY(-50%);

  &:last-child {
    display: none;
  }

  @media screen and (max-width: $mobileScreen) {
    display: none;
    //background: white;
    //height: 27px;
  }
}

.showGeneralKpiInfoTriangle {
  position: absolute;
  top: 0;
  transform: translate(50%, -98%);
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5.5px 7px 5.5px;
  border-color: transparent transparent #fff transparent;
}

.showGeneralKpiInfo {
  position: absolute;
  border: 1px solid white;
  background: white;
  z-index: 2;
  padding: 0.75rem 1rem;
  margin-top: -3.5rem;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.1), 0 15px 15px -5px rgba(0, 0, 0, 0.25), 0 20px 25px 0 rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  text-align: center;
  width: 90%;
  left: 5%;
}

.kpiInfo {
  display: none;
  @media screen and (max-width: $mobileScreen) {
    display: flex;
    height: 100%;
    padding: 0 15px;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(50%);
  }
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 90%;
  text-align: center;
  padding: 0 10px;
  margin: auto;
  align-items: center;
  justify-content: center;
  color: white;
  opacity: 0;
  transition: all 0.2s ease-in;
}

.kpiInfo.active {
  opacity: 1;
}

.kpiLarge {
  padding: 10px 0 25px;
  flex: 1;

  .kpiValue {
    font-size: 50px;
    @media screen and (max-width: 1440px) {
      font-size: 32px;
    }

    @media screen and (max-width: $mobileScreen) {
      font-size: 20px;
    }

    .kpiValuePrecision {
      margin-bottom: 9px;
      margin-left: 0;
      font-size: 30px;

      @media screen and (max-width: 1440px) {
        font-size: 16px;
      }

      @media screen and (max-width: $mobileScreen) {
        margin-bottom: 3.75px !important;
        font-size: 15px;
      }
    }
  }

  .kpiName {
    font-size: 19px;
    @media screen and (max-width: 1440px) {
      font-size: 1.1vw;
    }

    @media screen and (max-width: $mobileScreen) {
      font-size: 16px;
      padding: 0 15px;
    }
  }
}

.mobileAccountData {
  @media screen and (min-width: 1001px) {
    display: none !important;
  }

  @media screen and (max-width: $mobileScreen) {
    padding: 0 20px !important;
  }

  .kpiLarge {
    @media screen and (max-width: $mobileScreen) {
      display: flex;
      height: 87px;
      flex-direction: column;
      justify-content: center;
      margin: 0 20px 5px 0 !important;
      align-items: center;
      background: linear-gradient(120.55deg, rgba(255, 255, 255, 0.5) 47.37%, rgba(213, 213, 213, 0.5) 117.29%), #ffffff;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      min-width: 240px;
    }
  }
}

.mobileMainKpi {
  @media screen and (min-width: 1001px) {
    display: none;
  }
  padding-top: 75px;

  .kpiInfo {
    font-size: 19px;
    padding: 0 25px;
  }

  .infoButtonMobile {
    margin-top: 0;
    right: 5px;
    margin-left: -5px;
    margin-right: -18px;
    @media screen and (max-width: 380px) {
      margin-right: 0;
      //font-size: 52px !important;
    }
  }

  .kpiAccountName {
    text-align: center;
    color: var(--linkColor);
    font-size: 28px;
    margin-bottom: -0.25rem;
    margin-top: 1.25rem;
  }

  .kpi {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;

    .kpiValue {
      font-size: 56px !important;
      margin-bottom: 5px;
      @media screen and (max-width: $mobileScreen) {
        font-weight: 600 !important;
      }

      @media screen and (max-width: 420px) {
        font-size: 52px !important;
      }
    }

    .kpiValuePrecision {
      font-size: 29px !important;
      margin-bottom: 11.5px !important;
    }

    .kpiName {
      display: flex;
      align-items: center;
      font-size: 16px !important;
      font-weight: 300;
    }
  }
}

.infoButtonMobile {
  position: absolute;
  right: -15px;
  width: 14px;
  display: none;
  border-radius: 50%;
  overflow: hidden;
  //margin-left: 25px;
  margin-top: 0;
  margin-bottom: -2px;
  @media screen and (max-width: $mobileScreen) {
    display: inline-block;
  }
}

.infoButton {
  @include infoIcon;

  .textWrapper {
    position: relative;
    font-size: 11px;
    display: flex;
    margin-top: 1px;
  }

  &:hover {
    .infoContent {
      display: block;
    }
  }

  .infoContent {
    display: none;
    top: 21px;
    transform: translateX(-50%);
    left: -100%;
    position: absolute;
    font-size: 13px;
    border-radius: 8px;
    width: 195px;
    font-weight: 300;
    padding: 15px;
    box-sizing: content-box;
  }

  @media screen and (max-width: 1440px) {
    font-size: 13px;
    width: 13px;
    height: 13px;
    line-height: 14px;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.calendarToRight {
  // transform: translateX(-66%);
  @media screen and (max-width: $mobileScreen) {
    transform: translateX(0);
  }
}

.dashboardMainContent {
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0;
  margin-top: 16px;
  @media screen and (max-width: $mobileScreen) {
    overflow-x: hidden;
  }
}

#dashboardMainColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: $mobileScreen) {
    overflow: unset;
    display: unset;
    flex-direction: unset;
  }
}

#mobileDashboardHeader {
  @media screen and (min-width: $mobileScreen) {
    background-image: var(--greenGradient);
    border-bottom-left-radius: 0;
  }

  background: var(--greenGradient);
  background-image: url("../public/images/topDashboardBG.svg"), var(--greenGradient);
  background-position: top;
  background-size: 100%;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
  font-weight: 300;
  display: none;
  flex-direction: column;
  height: 282px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-top: 60px;

  #mainContent {
    margin: auto 0;
  }

  #grossValue {
    small {
      display: block;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 5px;
    }

    margin-bottom: 5px;
    font-size: 46px;
  }

  #ytd {
    font-size: 15px;

    small {
      font-size: 10px;
    }
  }

  #gtiYield {
    small {
      display: block;
      margin-bottom: 5px;
      font-size: 12px;
    }

    font-size: 16px;
    margin-top: -20px;
    margin-bottom: 20px;
  }
}

.mobileTitle {
  display: none;
  color: white;
  font-weight: 400;
  text-align: center;
  background: var(--greenGradient);
  font-size: 20px;
  line-height: 46px;
  height: 46px;
  // padding: 0 15px;
  // width: 100vw;
  position: relative;
  // left: calc(-50vw + 50%);
  margin-top: 20px;

  @media screen and (max-width: $mobileScreen) {
    display: block !important;
  }
}

// Dashboard content area

#mainContent {
  margin: 16px 26px 0;

  @media screen and (min-width: $mobileScreen) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  @media screen and (max-width: $mobileScreen) {
    margin: 70px 9px 0;
  }
}

#mainInnerContent {
  padding: 0 26px;
  margin: 0 26px;
  background: white;
  // border-top: 1px solid rgba(0, 0, 0, 0.05);
  //width: 100%;
  overflow-y: auto;
  height: calc(100vh - 100px);

  @media screen and (max-width: $mobileScreen) {
    padding: 15px 17px 26px;
    margin: 0;
    overflow: unset;
    min-height: 100vh !important;
    margin: unset;
    height: unset !important;
  }
}

#mainInnerContent.fullPage {
  @media screen and (max-width: $mobileScreen) {
    padding-top: 5rem;
    min-height: unset !important;
    height: unset;
  }
}

.mainInnerContentMobile {
  @media screen and (max-width: $mobileScreen) {
    padding: 9px 17px 26px;
    margin: 0 16px 35px;
    min-height: 100% !important;
  }
}

.dashboardContent {
  background: white;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 1rem;

  &.imageOnly {
    cursor: pointer;
    width: 445px;
    height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  img {
    width: 100%;
  }

  @media screen and (max-width: $mobileScreen) {
    padding: 0.5rem;
    &.imageOnly {
      width: 100%;
      height: 100%;
    }
  }
}

.noResults {
  height: 220px;
  background: rgba(196, 196, 196, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 8px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;

  .comingSoon {
    position: relative;
    align-items: center;
    gap: 6px;
    display: flex;
    font-style: normal;

    img {
      width: 17px;
    }
  }
}

#sideBarLinks {
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;

  li {
    list-style: none;
    height: 49px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    /* padding-left: 15px; */
    /* color: #595f77; */
    /* cursor: pointer; */
    /* position: relative; */
    // margin: 0 30px;

    @media screen and (max-width: 1440px) {
      // padding: 0 15px;
      margin: 0 10px;
    }

    @media screen and (max-width: $mobileScreen) {
      margin: 0;
    }

    a {
      font-size: 16px;
    }

    .icon {
      background: #595f77;
      width: 22px;
      height: 25px;
      /* margin-right: 21.4px; */
      margin: 12px;
    }

    .dashboardIcon {
      mask: url("../public/images/dashboard.svg") no-repeat center / contain;
    }

    .magazineIcon {
      mask: url("../public/images/magazine.svg") no-repeat center / contain;
    }

    .faqIcon {
      mask: url("../public/images/faq.svg") no-repeat center / contain;
    }

    .aboutIcon {
      mask: url("../public/images/about.svg") no-repeat center / contain;
    }

    // &:hover {
    //   background: rgba(105, 188, 123, 0.95);
    //   color: #fff;

    //   .icon {
    //     background: #fff;
    //   }
    // }
  }
}

.hideMobile {
  @media screen and (max-width: $mobileScreen) {
    display: none !important;
  }
}

.showMobile {
  display: none !important;
  @media screen and (max-width: $mobileScreen) {
    display: block !important;
  }
}

.closeSideBar {
  margin-right: auto;
  @media screen and (max-width: $mobileScreen) {
    margin-right: unset;
    margin-left: auto;
    padding: 1rem;
  }
}

.tabs {
  display: flex;
  align-items: center;
  flex: 1 0 auto;

  @media screen and (max-width: $mobileScreen) {
    position: absolute;
    margin-top: -129px;
    margin-bottom: -20px;
    margin-right: 0;
    gap: 23px;
  }

  div {
    font-size: 20px;
    font-weight: 400;
    color: var(--textColor);
    margin-right: 41px;
    padding-bottom: 7px;
    cursor: pointer;
    opacity: 0.5;
    transition: all 300ms cubic-bezier(0, 0.54, 0.735, 1.02);
    border-bottom: 4px solid transparent;
    position: relative;

    @media screen and (max-width: 1440px) {
      font-size: 15px;
      margin-right: 12px;
    }

    @media screen and (max-width: 480px) {
      font-size: 4vw !important;
      margin-right: 12px;
    }

    //@media screen and (max-width: 360px) {
    //  font-size: 14px !important;
    //  margin-right: 12px;
    //}

    &::after {
      transition: all 300ms cubic-bezier(0, 0.54, 0.735, 1.02);
      opacity: 0;
      content: "";
      width: 96%;
      height: 4px;
      background: var(--linkColor);
      position: absolute;
      left: 2%;
      bottom: -1px;
      border-radius: 8px;

      @media screen and (max-width: 1440px) {
        height: 3px;
      }
    }

    @media screen and (max-width: $mobileScreen) {
      font-size: 17px;
      margin-right: 0;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  div.active {
    opacity: 1;

    &::after {
      opacity: 1;
    }
  }

  div.activeTrackerRef {
    opacity: 1;

    &::after {
      opacity: 1;
      background: var(--blue);
    }
  }
}

#tabsArea {
  // height: 430px;

  .viewMoreLink {
    padding-left: 20px;
    @media screen and (max-width: $mobileScreen) {
      margin-top: 21px;
      margin-bottom: -5px;
      text-align: center;
      display: block;
      width: 100%;
    }
  }
}

.kpiTransitionWrapper {
  transition: all 0.2s ease-in;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.kpiTransitionWrapper.active {
  opacity: 0;
}

#chartsContent {
  display: flex;
  align-items: center;
  gap: 19px;
  margin-top: 16px;
  margin-bottom: 10px;
  div {
  }

  .kpiWrapper {
    width: 42%;
    margin-right: 0;
    background: white;
  }

  .chartWrapper {
    width: 50%;
    height: 100%;
    @media screen and (max-width: 1440px) {
      padding: 0.5rem !important;
    }
    @media screen and (max-width: $mobileScreen) {
      width: 100%;
      height: 100%;
    }
    @media screen and (min-width: $mobileScreen) {
      padding: 10px;
    }
  }

  @media screen and (min-width: 1440px) {
    flex: 1 1;
  }

  @media screen and (min-width: 661px) {
    flex: unset;
    justify-content: space-between;
  }

  @media screen and (max-width: $mobileScreen) {
    display: block;
    div {
    }
  }
}

#expiredSession {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    display: none;
    text-align: center;
    line-height: 2rem;
    font-size: 16px;
  }
}

#accountDropDown {
  @media screen and (max-width: $mobileScreen) {
    margin-right: 0;
  }

  #dropdownToggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 10px;
    @media screen and (max-width: 1440px) {
    }
  }

  .unactiveAccount {
    opacity: 0.5;
    cursor: not-allowed !important;

    &:after {
      content: "Inactive";
      font-size: 13px;
      padding: 3px 6px;
      border-radius: 5px;
      color: var(--red);
    }
  }

  .accountAvatar {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px;
    flex-shrink: 0;
    @media screen and (max-width: $mobileScreen) {
    }
  }

  .accountName {
    display: flex;
    gap: 8px;
    font-weight: 500;
    color: var(--accountColor);
    align-items: center;
    margin-left: 8px;
    @media screen and (max-width: $mobileScreen) {
      display: none;
    }
  }

  .accountNameInDropdown {
    display: flex;
    flex-direction: column;
    align-items: center;

    .accountAvatar {
    }

    .accountName {
      font-size: 20px;
      font-weight: 500;
    }

    .accountEmail {
      direction: ltr;
      margin-top: 8px;
      font-size: 15px;
      color: var(--accountColor);
      font-weight: 300;
    }
  }

  .accountsDropdownModalRtl {
    direction: ltr;
    box-shadow: 0 3px 15px 0 rgba(15, 15, 15, 0.15), 0 10px 20px -5px rgba(15, 15, 15, 0.35);
    z-index: 9;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 340px;
    background: white;
    border-radius: 10px;
    padding: 13px 24px;

    @media screen and (max-width: $mobileScreen) {
      position: fixed;
      left: 0;
      right: unset;
      bottom: 0;
      top: unset;
      width: 100%;
      z-index: 9999999999;
      box-shadow: 0 0 0 1000px rgba(28, 34, 18, 0.37);
    }
  }

  .accountsDropdownModal {
    box-shadow: 0 3px 15px 0 rgba(15, 15, 15, 0.15), 0 10px 20px -5px rgba(15, 15, 15, 0.35);
    z-index: 9;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 340px;
    background: white;
    border-radius: 10px;
    padding: 10px 26px 50px 26px;

    @media screen and (max-width: $mobileScreen) {
      position: fixed;
      left: 0;
      right: unset;
      bottom: 0;
      top: unset;
      width: 100%;
      z-index: 9999999999;
      box-shadow: 0 0 0 1000px rgba(28, 34, 18, 0.37);
    }
  }

  .accountsDropdownList {
    border-bottom: 1px solid #ddd;
    max-height: 350px;
    overflow-y: auto;

    .accountRow {
      padding: 6px 0;
      margin: 0;
      cursor: pointer;
      font-size: 15px;
      font-weight: 300;
      color: var(--accountColor);
      border-bottom: 1px solid #ddd;
      display: flex;
      align-items: center;
      gap: 5px;
      transition: all 0.2s ease-in;

      .accountActivityStatus {
        margin-left: auto;
        margin-top: -2px;
        margin-bottom: -2px;
        display: flex;
        flex-shrink: 0;
        justify-content: flex-end;
        align-items: center;
        color: var(--accountColor);
        padding: 5px 5px 5px 8px;
        border-radius: 9px;
      }

      span {
        text-decoration: none;
      }

      .accountActivityCircle {
        width: 8px;
        height: 8px;
        margin: 5px;
        border-radius: 100%;
      }

      .accountActivityCircle.active {
        background: var(--linkColor);
      }

      .accountActivityCircle.new {
        background: var(--blue);
      }

      .accountActivityCircle.inactive {
        background: var(--accountColor);
      }

      .accountActivityCircle.closed {
        background: var(--red);
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        @media screen and (min-width: $mobileScreen) {
          background: rgba(196, 196, 196, 0.1);
        }
      }
    }

    .currentAccount {
      span {
        text-decoration: underline;
        flex-grow: 1;
      }

      color: var(--linkColor);

      .accountActivityStatus {
        //background-image: linear-gradient(112deg, rgba(145, 178, 87, 0.3) -59%, rgba(145, 177, 88, 0) 83%);
      }
    }

    .newStatus {
      color: var(--blue) !important;
    }

    .closedStatus {
      color: var(--red) !important;
    }

    .inactiveStatus {
      color: var(--accountColor);
    }

    .activeStatus {
      color: var(--linkColor) !important;
    }
  }

  .logout {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    height: 34px;
    background: #e05757;
    border-radius: 10px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .faceId {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    height: 34px;
    background: #6a6a6a;
    border-radius: 10px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.tabsWithFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: 1230px) {
    flex-wrap: wrap;
    gap: 15px;
  }
}

// Filters

.filtersGrid {
  display: flex;
  align-items: center;
  @media screen and (max-width: $mobileScreen) {
    margin-left: auto;
  }
}

.mobileFilterGridCenter {
  @media screen and (max-width: $mobileScreen) {
    justify-content: space-around;
    margin-left: 0;
    width: 100%;
  }
}

.IRDatePicker {
  margin-left: auto;
  @media screen and (max-width: $mobileScreen) {
    margin: 0;
  }
}

.filterButton {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  padding: 7px 16px;
  margin-right: 7px;
  font-size: 14px;
  color: var(--labelColor);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in;
  gap: 9px;

  @media screen and (max-width: 1440px) {
    font-size: 12px;
    padding: 7px 14px;
    margin-right: 5px;
  }

  @media screen and (max-width: $mobileScreen) {
    padding: 7px 10px;
  }

  &:hover {
    background: darken(#fff, 2%);
  }

  &.active {
    background: var(--mainColor);

    &:hover {
      background: lighten(#6e957a, 5%);
    }

    color: white;
  }

  &.grey {
    background: #edf0ee;

    &:hover {
      background: lighten(#edf0ee, 2%);
    }
  }

  &.round {
    border-radius: 50%;
    padding: 9px;
  }
}

.fixedFilterButtonWidth {
  min-width: 110px;

  svg {
    margin-left: auto;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 40px auto 0 auto;

  .activeItem {
    background: var(--linkColor) !important;
    color: white !important;
  }

  .paginationItem {
    cursor: pointer;
    border-radius: 50%;
    background: #ddd;
    transition: all 0.2s ease-in;
    width: 28px;
    height: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      background: lighten(#ddd, 5%);
    }
  }
}

.datePicker {
  position: absolute;
  width: 290px;
  height: 290px;
  padding: 1rem;
  border-radius: 10px;
  background: white;
  z-index: 999;
  top: 40px;
  right: 0;
  box-shadow: 0 3px 15px 0 rgba(15, 15, 15, 0.15), 0 10px 20px -5px rgba(15, 15, 15, 0.25);

  @media screen and (max-width: $mobileScreen) {
    position: fixed;
    bottom: 0;
    top: unset;
    width: 100%;
    right: 0;
    box-shadow: 0 0 0 1500px rgba(28, 34, 18, 0.37);
  }

  .pickYear {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .pickYearChervon {
    cursor: pointer;
    padding: 5px;
    margin: -5px;
  }

  .monthsContainer {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 30%);
    height: calc(100% - 25px);
  }

  .yearsContainer {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    //grid-template-rows: repeat(, 50px);
    //height: 100%;
    .datePickerMonth {
      height: 50px;
    }
  }

  .datePickerMonth {
    position: relative;
    display: flex;
    margin: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-align: center;
    transition: all 0.2s ease-in;
    cursor: pointer;

    &::before {
      transition: all 0.2s ease-in;
      left: 50%;
      transform: translateX(-50%);
      bottom: 8px;
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      z-index: 999;
    }

    &:hover {
      background: #edf0ee;
    }
  }

  .monthInRange {
    background: rgba(237, 240, 238, 0.75);
  }

  .disabledDatePicker {
    opacity: 0.3;
  }

  .selectedMonth {
    background: #edf0ee;

    &::before {
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      background: var(--linkColor);
      border-radius: 50%;
      z-index: 999;
    }
  }
}

.datePicker.years {
  height: unset;
}

.show {
  visibility: visible !important;
  opacity: 1 !important;
}

#mobileContactUs {
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: fixed;
  z-index: 10;
  right: 1rem;
  bottom: 1rem;
  color: white;
  font-weight: 500;
  background: var(--linkColor);
  width: 60px;
  height: 60px;
  font-size: 11px;
  text-align: center;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);

  @media screen and (max-width: $mobileScreen) {
    display: flex !important;
  }
}

.backToTop {
  color: var(--linkColor);
  font-weight: 500;
  margin: 15px auto 25px;
  display: block;
  text-align: center;
  font-size: 15px;

  .arrowUp {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid var(--linkColor);
    display: block;
    margin: 0 auto 5px;
  }
}

.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 99vh;
  margin: auto;
  background-image: linear-gradient(112deg, rgba(145, 178, 87, 0.3) -59%, rgba(145, 177, 88, 0) 83%);

  h1 {
    font-weight: 400;
    font-size: 18px;
    margin-top: 3rem;
  }

  .goBack {
    color: var(--linkColor);
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
  }
}

.mobilePageTitle {
  margin-top: 4.5rem !important;
  display: none;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  margin-bottom: -4.5rem;

  h1 {
    font-weight: 400;
    font-size: 18px;
  }

  @media screen and (max-width: $mobileScreen) {
    display: flex;
    //display: block;
  }
}

.mobileAboutPageTitle {
  margin-top: 7.5rem !important;
  display: none;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  margin-bottom: -0.5rem;

  h1 {
    font-weight: 400;
    font-size: 18px;
  }

  @media screen and (max-width: $mobileScreen) {
    display: flex;
    //display: block;
  }
}

.innerContentWrapperAutoHeight {
  min-height: unset;
  width: 100%;
  margin: 0 4px;

  @media screen and (max-width: $mobileScreen) {
    margin-top: 5rem !important;
    min-height: 100%;
  }
}

.showMoreArticles {
  a {
    font-size: 18px;
    margin-bottom: 1rem;
  }
}

// Image zoom design

.imageZoomOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  overflow: auto;
  background: rgba(0, 0, 0, 0.8);

  .overlayWrapper {
    margin: auto;
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    div {
      overflow: unset;
    }
  }

  .zoomImage {
    height: 100%;
    max-width: 95%;
    margin: auto;
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .closeZoom {
    z-index: 99;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  .zoomImageClickable {
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}

// Cookies consent

#cookiesConsent {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: white;
    display: flex;
    align-items: center;
    @media screen and (max-width: $mobileScreen) {
      display: block;
      line-height: 25px;
    }
    gap: 5px;

    a {
      color: white;
    }
  }
  @media screen and (max-width: $mobileScreen) {
    flex-direction: column;
  }
  #cookiesGotIt {
    background: black;
    border-radius: 25px;
    width: fit-content;
    padding: 5px 15px;
    color: white;
    cursor: pointer;
    margin-left: 10px;
    @media screen and (max-width: $mobileScreen) {
      display: inline-block;
      background: black;
      border-radius: 25px;
      padding: 5px 15px;
      cursor: pointer;
      color: white;
      width: 130px;
      height: 60px;
      text-align: center;
      line-height: 48px;
      font-size: 19px;
    }
  }
}

#gtiYieldChart {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  figure {
    font-size: 54px;
    font-weight: bold;
    color: var(--lightGreen);
    margin: 0;
    line-height: 81.77px;
    @media screen and (max-width: 1440px) {
      font-size: 48px;
      line-height: 60px;
    }
  }

  span {
    margin: 0 0 25px;
    font-size: 20px;
    @media screen and (max-width: 1440px) {
      margin: 0 0 15px;
      font-size: 18px;
    }
  }

  small {
    text-align: center;
    font-weight: 500;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.67vw;
    width: 93%;
    @media screen and (max-width: 1440px) {
      font-size: 11px;
      line-height: 15px;
      font-weight: 400;
    }
  }
}
