$mobileScreen: 1080px;
$blockHover: 0 22px 33px -10px rgba(46, 56, 29, 0.4);
$blockImageShadow: 0 42px 103px -30px rgba(46, 56, 29, 0.4);
$blockTransition: all 0.2s ease-in;
$overlay: rgba(28, 34, 18, 0.37);

@mixin defaultText {
  text-align: inherit;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #000000;

  @media screen and (max-width: $mobileScreen) {
    font-size: 16px;
    line-height: 24px;
  }

  span:first-child {
    font-size: inherit !important;
    line-height: inherit !important;
  }

  * {
    text-align: inherit;
    font-size: 22px;
    line-height: 33px;
    color: #000000;

    @media screen and (max-width: $mobileScreen) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  u,
  i,
  em,
  mark {
    font-weight: 400;
  }

  b,
  strong {
    font-weight: 500;
  }

  b,
  em,
  i,
  u,
  mark,
  strong {
    font-size: inherit;
  }

  p,
  li,
  ul {
    text-align: inherit;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    color: #000000;

    @media screen and (max-width: $mobileScreen) {
      font-size: 16px;
      line-height: 24px;
    }

    span:first-child {
      font-size: inherit !important;
      line-height: inherit !important;
    }

    * {
      text-align: inherit;
      font-size: 22px;
      line-height: 33px;
      color: #000000;

      @media screen and (max-width: $mobileScreen) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  blockquote {
    position: relative;
    font-style: italic;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    color: #000000;
    margin-left: 0;
    padding-left: 20px;
    @media screen and (max-width: $mobileScreen) {
      font-size: 16px;
      line-height: 24px;
    }

    u,
    i,
    em,
    mark {
      font-weight: 400;
    }

    b,
    strong {
      font-weight: 500;
    }

    b,
    em,
    i,
    u,
    mark,
    strong {
      font-size: inherit;
    }

    p {
      margin: 0;
    }

    &::before {
      content: "";
      position: absolute;
      width: 7px;
      border-radius: 8px;
      height: 100%;
      left: 0;
      top: 0;
      background: black;
    }
  }

  figcaption {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    text-align: center;
    @media screen and (max-width: $mobileScreen) {
      font-size: 14px;
      line-height: 21px;
    }

    u,
    i,
    em,
    mark {
      font-weight: 400;
    }

    b,
    strong {
      font-weight: 500;
    }

    b,
    em,
    i,
    u,
    mark,
    strong {
      font-size: inherit;
    }
  }
}

.textContentRules {
  .image-style-side {
    float: right !important;
    @media screen and (max-width: $mobileScreen) {
      float: none !important;
      width: 100% !important;
    }
  }

  h2 {
    margin-top: 2.5rem;
  }

  ol,
  ul {
    margin-bottom: 2rem;
    margin-left: 0;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
  }

  p {
    margin-bottom: 1.75rem;
    display: block;
    width: 100% !important;
    word-break: break-word;
    white-space: normal;

    @media screen and (max-width: $mobileScreen) {
      width: 98% !important;
    }
  }

  figure {
    margin: 0 !important;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
    padding: 0;
  }

  .table {
    overflow-x: auto;
  }

  table {
    border-spacing: 0;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid #ddd;
    box-shadow: 0 5px 35px 0 rgba(15, 15, 15, 0.15);
    padding: 0 !important;

    tr:first-of-type {
      td {
        background: #f1f1f1 !important;
      }
    }

    tr:nth-of-type(even) {
      background: white;
    }

    tr:nth-of-type(odd) {
      background: #f4f4f4;
    }

    td {
      vertical-align: top;
      padding: 10px;
      border-bottom: 1px solid darken(#f1f1f1, 10%);

      p,
      span,
      div {
        font-size: inherit;
        margin: 0;
        line-height: inherit;
        * {
          line-height: inherit;
          margin: 0;
          font-size: inherit;
        }
      }
    }

    tr:last-of-type {
      td {
        border-bottom: none;
      }
    }
  }

  iframe {
    width: 100%;
  }

  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #000000;
    margin-bottom: 0;

    @media screen and (max-width: $mobileScreen) {
      font-size: 26px;
      line-height: 39px;
    }

    * {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      color: #000000;

      @media screen and (max-width: $mobileScreen) {
        font-size: 26px;
        line-height: 39px;
      }
    }

    u,
    i,
    em,
    mark {
      font-weight: 700;
    }

    b,
    strong {
      font-weight: 500;
    }

    b,
    em,
    i,
    u,
    mark,
    strong {
      font-size: inherit;
    }
  }

  h2 {
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
    color: #000000;
    margin-bottom: 0;

    @media screen and (max-width: $mobileScreen) {
      font-size: 22px;
      line-height: 33px;
    }

    * {
      font-weight: 400;
      font-size: 28px;
      line-height: 42px;
      color: #000000;

      @media screen and (max-width: $mobileScreen) {
        font-size: 22px;
        line-height: 33px;
      }
    }

    u,
    i,
    em,
    mark {
      font-weight: 400;
    }

    b,
    strong {
      font-weight: 500;
    }

    b,
    em,
    i,
    u,
    mark,
    strong {
      font-size: inherit;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 36px;
    color: #000000;

    @media screen and (max-width: $mobileScreen) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  @include defaultText;
}

.coverImageWrapper {
  height: 380px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
  margin: -15px;
  background-size: cover;
  background-attachment: local;
  background-position: top;
  margin: -15px auto -15px auto;
  max-width: 720px;
  display: block;

  @media screen and (max-width: $mobileScreen) {
    margin-top: 54px;
    width: 104%;
    border-radius: 0;
    height: 220px;
  }
}

#logo {
  color: var(--mainColor);
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 95px;
  display: flex;
  align-items: center;
  @media screen and (max-width: $mobileScreen) {
    text-align: center;
    justify-content: center;
  }
}
